



import { Component, Mixins, Prop, Vue, Watch } from 'vue-property-decorator'
import { getDefaultChartOptions } from '#/const/charts'
import { ChartMethods } from '#/mixins/chart-methods'
import { analyticsColors } from '#/const/analytics-colors'
import { ChartComputed } from '#/mixins/chart-computed'
import { Chart } from 'chart.js'

@Component
export default class BeatUploads extends Mixins(ChartMethods, ChartComputed) {
	@Prop() chartPoints: IAnalyticsDataDetailed
	@Prop({ type: Boolean, default: true }) showLast7Days: boolean

	getChartId() {
		return 'beatUploadsChart'
	}

	@Watch('showLast7Days', { immediate: true })
	periodChanged() {
		Vue.nextTick(() => {
			const chartOptions = getDefaultChartOptions()
			chartOptions.scales.x.afterTickToLabelConversion = this.dateAfterTickToLabelConversion
			// @ts-ignore
			chartOptions.tooltips.callbacks.label = (tooltipItem, data) => {
				const quantity = data['datasets'][0]['data'][tooltipItem['index']]
				return `${quantity} beat${quantity > 1 ? 's' : ''} uploaded`
			}
			const ctx = (document.getElementById(this.getChartId()) as HTMLCanvasElement).getContext('2d')!

			if (!this.chart)
				this.chart = new Chart(ctx, {
					type: 'bar',
					// @ts-ignore
					options: chartOptions,
					data: {
						datasets: []
					}
				})

			this.chart.data = {
				labels: this.showLast7Days ? this.last7Days : this.last30Days,
				datasets: [
					{
						data: this.showLast7Days ? this.chartPoints.by_days.last_7_days : this.chartPoints.by_days.last_30_days,
						...this.generateChartStyling({
							hex: analyticsColors.beatUpload.hex,
							colorRgb: analyticsColors.beatUpload.rgb
						})
					}
				]
			}
			this.chart.update()
		})
	}
}
