











































import { Component, Mixins } from 'vue-property-decorator'

import { UserStore } from '@admin/store/user'
import { Dropdowns } from '~/mixins/dropdowns'

import VLoadSpinner from '~/components/VLoadSpinner.vue'
import { fetchDetailedBeatPlays, fetchDetailedPageVisits  } from '#/api/analytics'
import { fetchDetailedBeatUploads, fetchDetailedSignups } from '@admin/api/analytics'
import BeatPlays from '#/components/charts/BeatPlays.vue'
import PageViews from '#/components/charts/PageViews.vue'
import BeatUploads from '@admin/components/charts/BeatUploads.vue'
import Signups from '@admin/components/charts/Signups.vue'

@Component({
	components: {
		VLoadSpinner,
		BeatPlays,
		PageViews,
		BeatUploads,
		Signups
	}
})
export default class DashboardPage extends Mixins(Dropdowns) {
	isLoading = true
	periods = [
		{
			id: 'last7days',
			showText: 'Last 7 Days'
		},
		{
			id: 'last30days',
			showText: 'Last 30 Days'
		}
	]
	selectedPeriod = this.periods[0]
	detailedBeatPlays: IAnalyticsDataDetailed = null
	detailedPageVisits: IAnalyticsDataDetailed = null
	detailedBeatUploads: IAnalyticsDataDetailed = null
	detailedSignups: IAnalyticsDataDetailed = null

	@UserStore.State('authenticatedUser') authenticatedUser: IUser

	setPeriod(periodIndex: number) {
		// if the upload was an image, we simply match the ids
		this.closeMenuDropdown()
		this.selectedPeriod = this.periods[periodIndex]
	}

	async mounted() {
		this.isLoading = true
		try {
			this.detailedBeatPlays = await fetchDetailedBeatPlays()
			this.detailedPageVisits = await fetchDetailedPageVisits()
			this.detailedBeatUploads = await fetchDetailedBeatUploads()
			this.detailedSignups = await fetchDetailedSignups()
		} catch (error) {
			console.error(error)
		} finally {
			this.isLoading = false
		}
	}
}
