



import { Component, Mixins, Prop, Vue, Watch } from 'vue-property-decorator'
import { getDefaultChartOptions } from '#/const/charts'
import { ChartMethods } from '#/mixins/chart-methods'
import { ChartComputed } from '#/mixins/chart-computed'
import { Chart } from 'chart.js'
import { analyticsColors } from '#/const/analytics-colors'

@Component
export default class Signups extends Mixins(ChartMethods, ChartComputed) {
	@Prop() chartPoints: IAnalyticsDataDetailed
	@Prop({ type: Boolean, default: true }) showLast7Days: boolean

	getChartId() {
		return 'signupsChart'
	}

	@Watch('showLast7Days', { immediate: true })
	periodChanged() {
		Vue.nextTick(() => {
			const chartOptions = getDefaultChartOptions()
			chartOptions.scales.x.afterTickToLabelConversion = this.dateAfterTickToLabelConversion
			// @ts-ignore
			chartOptions.tooltips.callbacks.label = (tooltipItem, data) => {
				const quantity = data['datasets'][0]['data'][tooltipItem['index']]
				return `${quantity} user${quantity > 1 ? 's' : ''} signed up`
			}
			const ctx = (document.getElementById(this.getChartId()) as HTMLCanvasElement).getContext('2d')!
			// @ts-ignore
			const myChart = new Chart(ctx, {
				type: 'bar',
				// @ts-ignore
				options: chartOptions,
				data: {
					labels: this.showLast7Days ? this.last7Days : this.last30Days,
					datasets: [
						{
							data: this.showLast7Days ? this.chartPoints.by_days.last_7_days : this.chartPoints.by_days.last_30_days,
							...this.generateChartStyling({
								hex: analyticsColors.signups.hex,
								colorRgb: analyticsColors.signups.rgb
							})
						}
					]
				}
			})
			myChart.update()
		})
	}
}
