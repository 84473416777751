import { get, post, remove, update } from '~/services/http-requests'

const _BS_ANALYTICS = 'analytics'

const organizeProperties = (arr: any) => ({
    by_days: { last_7_days: arr.by_days.slice(arr.by_days.length - 7), last_30_days: arr.by_days },
    by_months: arr.by_months
})

export async function fetchDetailedBeatUploads(): Promise<IAnalyticsDataDetailed> {
    const data = await get({ baseProperty: _BS_ANALYTICS, fullUrl: `${_BS_ANALYTICS}/detailed_beat_uploads/` })
    return organizeProperties(data)
}

export async function fetchDetailedSignups(): Promise<IAnalyticsDataDetailed> {
    const data = await get({ baseProperty: _BS_ANALYTICS, fullUrl: `${_BS_ANALYTICS}/detailed_signups/` })
    return organizeProperties(data)
}
